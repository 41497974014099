<template>
	<div class="mt-1">
		<div>
			<v-row>
				<v-col cols="3">
					<v-text-field
						label="*First Name"
						hide-details="auto"
						v-model="complianceOfficer.first_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
										
				<v-col cols="3">
					<v-text-field
						label="*Middle Name"
						hide-details="auto"
						v-model="complianceOfficer.middle_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col cols="3">
					<v-text-field
						label="*Last Name"
						hide-details="auto"
						v-model="complianceOfficer.last_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col cols="3">
					<v-text-field
						label="Suffix"
						hide-details="auto"
						v-model="complianceOfficer.suffix"
						outlined
						dense
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
									
			<v-row>
				<v-col>
					<h4>Primary Contact Address</h4>
				</v-col>
			</v-row>

			<v-checkbox
				label="Same As Primary Address"
				v-model="complianceOfficer.is_same_primary"
				@click="sameAddress"
				:disabled="checkAddress() || disabled"
				>
			</v-checkbox>
									
			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 1"
						hide-details="auto"
						v-model="complianceOfficer.address_line_1"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
								
			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 2"
						hide-details="auto"
						v-model="complianceOfficer.address_line_2"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						label="*Country"
						outlined
						item-text="country"
						item-value="country"
						hide-details="auto"
						v-model="complianceOfficer.country"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
				</v-col>
				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						v-if="disabled == true"
						label="*Province"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.province"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Province"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.province"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						@change="getRegion"
						:items="province"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || complianceCity.length == 0"
						label="*City"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.city"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*City"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.city"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="complianceCity"
						@change="getZip"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						label="*Region"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.region"
						dense
						readonly
						:rules="genericRule"
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || complianceZip.length == 0"
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.postal_code"
						dense
						type="number"
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="complianceOfficer.postal_code"
						dense
						type="number"
						:rules="genericRule"
						hide-spin-buttons
						:items="complianceZip"
						:readonly="disabled"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
		</div>
		
		<div>
			<v-row>
				<v-col>
					<h4>Required Documents</h4>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col cols="4">
					<v-text-field
						label="Landline Number"
						hide-details="auto"
						v-model="complianceOfficer.landline"
						outlined
						dense
						type="number"
						hide-spin-buttons
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
										
				<v-col cols="4">
					<v-text-field
						label="*Cellphone Number"
						hide-details="auto"
						v-model="complianceOfficer.cellphone"
						outlined
						dense
						type="number"
						hide-spin-buttons
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col cols="4">
					<v-text-field
						label="*Email"
						hide-details="auto"
						v-model="complianceOfficer.email_address"
						outlined
						dense
						:readonly="disabled"
						:rules="emailRule"
						>
					</v-text-field>
				</v-col>
			</v-row>
			<!--ADDING OF AMLA CERTIFICATE AND VALID ID FILE UPLOADING AND VIEWING ICON-->
			<v-row>
				<v-col cols="4">
          <v-file-input
					v-model="ReAccreditationComplianceOfficer.amla_certificate.file"
					label="AML Certificate"
					accept=".pdf"
					prepend-icon="mdi-paperclip"
					hide-details="auto"
					:disabled="disabled"
					dense
					outlined	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'amla_certificate')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				</v-col>
				<v-col :cols="1" class="mt-1" v-if="existingUploadedCompliance.amla_certificate">
						<v-icon
								@click="retrieveUploaded(existingUploadedCompliance.amla_certificate)"
								>
								mdi-eye
						</v-icon>
				</v-col>
										
				<v-col cols="4">
          <v-file-input
					v-model="ReAccreditationComplianceOfficer.valid_id.file"
					:disabled="disabled"
					label="Valid ID"
					accept=".pdf"
					prepend-icon="mdi-paperclip"
					hide-details="auto"
					dense
					outlined
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'valid_id')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				</v-col>
				<v-col :cols="1" class="mt-1" v-if="existingUploadedCompliance.valid_id">
						<v-icon
								@click="retrieveUploaded(existingUploadedCompliance.valid_id)"
								>
								mdi-eye
						</v-icon>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['disabled'],
    computed: {
		...mapGetters({
			complianceOfficer: 'salesProspecting/complianceOfficer',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			genericRule: 'salesProspecting/genericRule',
			emailRule: 'salesProspecting/emailRule',
			province: 'salesProspecting/province',
			complianceCity: 'salesProspecting/complianceCity',
			complianceZip: 'salesProspecting/complianceZip',
			hrisToken: 'auth/hrisToken',
			ReAccreditationComplianceOfficer: 'salesProspecting/ReAccreditationComplianceOfficer',
			existingUploadedCompliance: 'salesProspecting/existingUploadedCompliance'
		}),
	},
	methods:{
		checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
				return false
			} else {
				return true
			}
		},
		sameAddress () {
			if (this.complianceOfficer.is_same_primary){
				console.log('check')
				this.complianceOfficer.is_same_primary = true
				this.complianceOfficer.address_line_1 = this.primaryBusinessAddress.address_line_1
				this.complianceOfficer.address_line_2 = this.primaryBusinessAddress.address_line_2
				this.complianceOfficer.country = this.primaryBusinessAddress.country
				this.complianceOfficer.city = this.primaryBusinessAddress.city
				this.complianceOfficer.region = this.primaryBusinessAddress.region
				this.complianceOfficer.postal_code = this.primaryBusinessAddress.postal_code
				this.complianceOfficer.province = this.primaryBusinessAddress.province
				this.complianceOfficer.landline = this.primaryBusinessAddress.landline
				this.complianceOfficer.cellphone = this.primaryBusinessAddress.cellphone
			} else {
				console.log('uncheck')
				console.log(this.complianceOfficer.is_same_primary)
				this.complianceOfficer.address_line_1 = ''
				this.complianceOfficer.address_line_2 = ''
				this.complianceOfficer.country = 'Philippines'
				this.complianceOfficer.city = ''
				this.complianceOfficer.province = ''
				this.complianceOfficer.region = ''
				this.complianceOfficer.postal_code = ''
				this.complianceOfficer.landline = ''
				this.complianceOfficer.cellphone = ''
			}
		},
		async getRegion(province){
			this.complianceOfficer.postal_code = ''
			this.complianceOfficer.city = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetComplianceRegion', {payload})

			const finalPayload = {
				payload : this.complianceOfficer.region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGeComplianceCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.complianceOfficer.region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetComplianceZip', {payload})
		},
		async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
	}
};
</script>
