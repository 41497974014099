<template>
    <div class="mb-5">
        <v-expansion-panels>
            <v-expansion-panel v-for="(parentItem, parentIndex) in existingUploadedBod" :key="parentIndex">
                <v-expansion-panel-header>
                    <div>
                        <v-icon :disabled="disabled" @click.stop="" @click="deleteBOD(parentItem)" color="error" y>mdi-delete</v-icon>
                        &ensp;
                        <span v-if="parentItem.is_draft == true">{{parentItem.first_name}} {{parentItem.middle_name}} {{parentItem.last_name}} (Draft) </span>
                        <span v-if="parentItem.is_draft == false">{{parentItem.first_name}} {{parentItem.middle_name}} {{parentItem.last_name}}</span>
                    </div>
                </v-expansion-panel-header>
                <v-form ref="uploadedBod" v-model="uploadedBod">
                    <v-expansion-panel-content eager>
                        <v-row class="mb-3">
                            <v-col cols="3">
                                <v-text-field
                                    label="*First Name"
                                    hide-details="auto"
                                    v-model="parentItem.first_name"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Middle Name"
                                    hide-details="auto"
                                    v-model="parentItem.middle_name"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Last Name"
                                    hide-details="auto"
                                    v-model="parentItem.last_name"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Suffix"
                                    hide-details="auto"
                                    v-model="parentItem.suffix"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Number of Shares"
                                    hide-details="auto"
                                    v-model="parentItem.no_of_shares"
                                    outlined
                                    dense
                                    type="number"
                                    hide-spin-buttons
                                    :readonly="disabled"
                                    @change="generateID"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Position"
                                    hide-details="auto"
                                    v-model="parentItem.position"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>

                            <v-col :cols="3">
                                <v-menu
                                    v-model="parentItem.menu.menuIssued"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="parentItem.date_of_birth"
                                            label="*Date of Birth"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            :rules="existingGenericRule"
                                            outlined
                                            :disabled="disabled"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="parentItem.date_of_birth"
                                        @input="parentItem.menu.menuIssued = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>


                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-checkbox
                                    label="Is Familial"
                                    v-model="parentItem.is_familial"
                                    hide-details="auto"
                                    :readonly="disabled"
                                    >
                                </v-checkbox>
                            </v-col> 
                        </v-row>
                        <section v-if="parentItem.valid_idUploaded">
                            <v-row v-if="parseInt(parentItem.no_of_shares) >= 10">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.valid_id.file"
                                        label="*Valid ID"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.valid_idUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.valid_idUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-if="parseInt(parentItem.no_of_shares) >= 10">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.amla_certificate.file"
                                        label="*AMLA Certificate"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.amlaUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.amlaUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-if="parseInt(parentItem.no_of_shares) >= 10">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.nbi_clearance.file"
                                        label="*NBI Clearance"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.nbiUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.nbiUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </section>
                        <section v-else>
                            <v-row v-if="parseInt(parentItem.no_of_shares) >= 10">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.valid_id.file"
                                        label="*Valid ID"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :rules="existingFileRUle"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.valid_idUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.valid_idUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                                <v-row>
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.amla_certificate.file" 
                                        label="AMLA Certificate"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.amlaUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.amlaUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.nbi_clearance.file" 
                                        label="NBI Clearance"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.nbiUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.nbiUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            </v-row>
                        </section>
                        <v-row>
                            <v-col>
                                <h4>Primary Contact Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="*Address Line 1"
                                    hide-details="auto"
                                    v-model="parentItem.address_line_1"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="*Address Line 2"
                                    hide-details="auto"
                                    v-model="parentItem.address_line_2"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="2"
                                >
                                <v-text-field
                                    label="*Country"
                                    item-text="country"
                                    item-value="country"
                                    v-model="parentItem.country"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    readonly
                                >
                                </v-text-field>
                            </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        v-if="disabled == true"
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        :readonly="disabled"
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                        @change="getRegion"
                                        @click="getProvinceIndex(parentIndex)"
                                        :items="province"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingBodCity[parentIndex]"
                                        label="*City"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*City"
                                        outlined
                                        no-filter
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                        :items="existingBodCity[parentIndex]"
                                        @change="getZip"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="*Region"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.region"
                                        dense
                                        readonly
                                        :rules="existingGenericRule"
                                        >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingBodZip[parentIndex]"
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :items="existingBodZip[parentIndex]"
                                        :readonly="disabled"
                                        >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!-- NOT DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == false">
                                    <v-btn outlined v-if="!disabled" color="primary" style="float:right;" @click="updateBod(parentItem, parentIndex)">
                                        Update BOD
                                    </v-btn>
                                </v-col>
                                <!-- DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == true">
                                    <v-btn v-if="!disabled" class="ml-3" color="primary" style="float:right;" @click="updateBod(parentItem, parentIndex)">
                                        ADD BOD
                                    </v-btn>
                                    <v-btn outlined v-if="!disabled" color="primary" style="float:right;" @click="updateDraft(parentItem, parentIndex)">
                                        Update BOD Draft
                                    </v-btn>
                                </v-col>
                            </v-row>
                    </v-expansion-panel-content>
                </v-form>
            </v-expansion-panel>
        </v-expansion-panels>

            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title>
                        Delete Board of Director
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <p style="font-size: 15px;">Delete {{dialogDescription}}? You can't undo this.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelDialog"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmDialog"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
import _ from 'lodash';

export default {
    props: ['disabled'],
    data () {
		return {
            showID: null,
            uploadedBod: true,
            currentIndex: '',
            deleteDialog: '',
            dialogDescription: '',
            bodId: ''
        }
	},
    computed: {
		...mapGetters({
            existingUploadedBod: 'salesProspecting/existingUploadedBod',
            existingGenericRule: 'salesProspecting/existingGenericRule',
            existingEmailRule: 'salesProspecting/existingEmailRule',
            existingFileRUle: 'salesProspecting/existingFileRUle',
            province: 'salesProspecting/province',
            existingBodCity: 'salesProspecting/existingBodCity',
            existingBodZip: 'salesProspecting/existingBodZip',
            hrisToken: 'auth/hrisToken'
		}),
	},
    methods: {
        getProvinceIndex(index){
            this.currentIndex = index
        },
        async getRegion(province){
			this.existingUploadedBod[this.currentIndex].postal_code = ''
			this.existingUploadedBod[this.currentIndex].city = ''
			const payload = {
				payload : province,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBodRegion', {payload})

			const finalPayload = {
				payload : this.existingUploadedBod[this.currentIndex].region,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBodCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.existingUploadedBod[this.currentIndex].region,
				city : city,
				token: this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBodZip', {payload})
		},
        async deleteBOD(item){
            this.bodId = item.id
            this.dialogDescription = item.first_name
            this.deleteDialog = true
        },
        async confirmDialog(){
            const payload = {id: this.$route.params.id, bod_id: this.bodId}
            const res = await this.$store.dispatch('salesProspecting/doDeleteBod', {payload})
            if (res.status == 200){
                this.bodId = ''
                this.dialogDescription = ''
                this.deleteDialog = false
                this.reloadBod()
            }
        },
        cancelDialog(){
            this.bodId = ''
            this.dialogDescription = ''
            this.deleteDialog = false
        },
        checkRule (item) {
            if (item.file) {
                return [v => !!v || 'This field is required']
            }
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
        returnDateIssued (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            return max
        },
        updateDraft(item){
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            }
            let initialPayload = {...item, is_draft: true}
            delete initialPayload.id
            delete initialPayload.sales_prospects_id
            delete initialPayload.updated_at
            delete initialPayload.created_at
            delete initialPayload.menu
            if (initialPayload.valid_idUploaded) {
                delete initialPayload.valid_idUploaded
            }
            //ADDING OF AMLA AND NBI FOR PAYLOAD
            if (initialPayload.amlaUploaded) {
                        delete initialPayload.amlaUploaded
            }
            if (initialPayload.nbiUploaded) {
                delete initialPayload.nbiUploaded
            }
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            this.callUpdateBod(payload)
        },
        updateBod(item, index){
            const self = this
            self.$store.commit('salesProspecting/SET_RULE_GENERIC_EXISTING')
			self.$store.commit('salesProspecting/SET_RULE_EMAIL_EXISTING')
            self.$store.commit('salesProspecting/SET_RULE_FILE_EXISTING')
            setTimeout(() => {
                if (self.$refs.uploadedBod[index].validate()) {
                    var options = {
                        initialFormData: new FormData(),
                        howLeafArrayIndexes: true,
                        includeNullValues: false,
                        mapping: function(value) {
                            if (typeof value === 'boolean') {
                                return +value ? '1': '0';
                            }
                            return value;
                        }
                    }
                    let initialPayload = {...item, is_draft: false}
                    delete initialPayload.id
                    delete initialPayload.sales_prospects_id
                    delete initialPayload.updated_at
                    delete initialPayload.created_at
                    delete initialPayload.menu
                    if (initialPayload.valid_idUploaded) {
                        delete initialPayload.valid_idUploaded
                    }
                    //ADDING OF AMLA AND NBI FOR PAYLOAD
                    if (initialPayload.amlaUploaded) {
                        delete initialPayload.amlaUploaded
                    }
                    if (initialPayload.nbiUploaded) {
                        delete initialPayload.nbiUploaded
                    }
                    var convertedFormData = jsonToFormData(initialPayload, options)
                    const payload = {id: this.$route.params.id, payload: convertedFormData}
                    this.callUpdateBod(payload)
                }
            })
        },
        async callUpdateBod (payload) {
            await this.$store.dispatch('salesProspecting/doUpdateBod', {payload})
			this.reloadBod()
            this.$store.commit('salesProspecting/UNSET_RULE_GENERIC_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_EMAIL_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_FILE_EXISTING')
        },
        async reloadBod () {
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
            this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
            this.decodeBod(res.data.result[0].sp_bod)
        },
        decodeBod (resBod) {
            let birthdate = {
				menuIssued: false,
                menuExpiry: false
			}
            let nonExpiryFile = {
                file: null
            }
			resBod.forEach(function(e) {
                e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
                //SETTING OF AMLA AND NBI FOR BOD
                e.amlaUploaded = JSON.parse(e.amla_certificate)
				e.amla_certificate = _.clone(nonExpiryFile)
                e.nbiUploaded = JSON.parse(e.nbi_clearance)
				e.nbi_clearance = _.clone(nonExpiryFile)
                e.menu = _.clone(birthdate)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_BOD', resBod)
        },
        generateID(value){
            let newValue =  (parseInt(value))
            if (newValue >= 10){
                this.showID = true
            } else {
                this.showID = false
            }
        }
    }
}
</script>
