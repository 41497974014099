<template>
	<div class="mt-1">
		<!--RISK ASSESSMENT TOOL UPLOAD, REDIRECT, INPUT-->
      <v-row>
        <v-col cols="4">        
        <v-btn 
          outlined
          color="primary" 
          @click="redirectToExternalLink">
          PRP RISK ASSESSMENT TOOL
        </v-btn>
      </v-col>
      </v-row>
      <v-row>
			<v-col cols="4">
        <v-file-input
					v-model="prpRiskAssessmentToolDocuments.assessment_upload.file"
					:disabled="disabled"
					label="PRP Risk Assessment Tool"
					accept=".xlsx, .csv, .pdf"
					prepend-icon="mdi-paperclip"
					hide-details="auto"
					dense
					outlined
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'assessment_upload')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
        <v-list v-if="existingRiskAssessmentTool && existingRiskAssessmentTool[0] !== null">
					<v-list-item >
				<v-list-item-content>
					{{ existingRiskAssessmentTool[0]?.title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingRiskAssessmentTool[0].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
    </v-list>
			</v-col>
			<v-col cols="2">
    <v-text-field
        v-model.number="prpRiskAssessmentToolDetails.assessment_rating"
        :disabled="disabled"
        @input='riskScoring()'
        label="Risk Assessment Tool Rating"
        dense
        outlined
				:rules="genericRule"
        hide-details="auto"
        :style="disabled ? { fontWeight: 'bold'} : {}">
			</v-text-field>
				</v-col>
		<v-col cols="2">
    <v-text-field
        v-model="prpRiskAssessmentToolDetails.risk_assessment_profile"
        label="Risk Assessment Tool Profile"
        readonly
        disabled
        dense
        outlined
        hide-details="auto"
        :style="disabled ? { fontWeight: 'bold'} : {}">
			</v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	props: ['disabled', 'reaccreditation'],
    computed: {
		...mapGetters({
      salesProspectingForm: 'salesProspecting/salesProspectingForm',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded',
			genericRule: 'salesProspecting/genericRule',
			currUser: 'auth/currUser',
      ReAccreditationDetails: 'salesProspecting/ReAccreditationDetails',
      ReAccreditationinitialUploaded: 'salesProspecting/ReAccreditationinitialUploaded',
      existingRiskAssessmentTool: 'salesProspecting/existingRiskAssessmentTool',
      prpRiskAssessmentToolDocuments:  'salesProspecting/prpRiskAssessmentToolDocuments',
      prpRiskAssessmentToolDetails: 'salesProspecting/prpRiskAssessmentToolDetails',
		}),
	},
	mounted(){
		console.log(this.existingRiskAssessmentTool)
	},
	watch: {
	// ----------------------------------- WATCH WHEN A FILE IS CHOSEN VALIDATE FILE TYPES-----------------------------------------
		'prpRiskAssessmentTool.upload_risk_assessment_tool.upload_risk_assessment_tool'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_risk_assessment_tool', ['csv','xlsx','pdf']);
				console.log(this.existingRiskAssessmentTool)
		},
  },
	methods: {
		removeFile (index, property) {
			this.initialUploaded[property].splice(index, 1)
		},
		async retrieveUploaded (item) {
			const payload = {
				file: item
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsx') {
				//---------------------------- OPENING OF AML FORM IF XLSX ----------------------------------
				blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) 
			} else if (extension === 'csv') {
				//---------------------------- OPENING OF AML FORM IF CSV ----------------------------------
				blob = new Blob([file], { type: 'text/csv' }) 
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
		//--------------------- AUTOMATING AML PROFILE DISPLAY AND STORING DATA TO SALES PROSPECTING FORM ---------------------------
		riskScoring() {
    if(this.prpRiskAssessmentToolDetails.assessment_rating <= 3 && this.prpRiskAssessmentToolDetails.assessment_rating >= 2.34){			 
        this.prpRiskAssessmentToolDetails.risk_assessment_profile = 'Low Risk'
    } else if(this.prpRiskAssessmentToolDetails.assessment_rating >= 2 && this.prpRiskAssessmentToolDetails.assessment_rating <= 2.33){
        this.prpRiskAssessmentToolDetails.risk_assessment_profile = 'Moderate Risk'
    } else {
        this.prpRiskAssessmentToolDetails.risk_assessment_profile = 'High Risk'
    }
	},
	// ----------------------------------- VALIDATE FILES INPUTTED-----------------------------------------
	handleFileValidation(newFiles, fileType, types){
		//Checking if the file chosen is not null
		if(newFiles && newFiles.length > 0){ 
			const fileExtension = newFiles[0].name.split('.').pop().toLowerCase(); 
				//Checking if the file chosen is the accepted file type
				if (!types.includes(fileExtension)) { 
					//dispatch snackbar for alert
					this.$store.dispatch('app/setSnackBar',{'status': true, 'message':`Wrong File Type For ${fileType}: Only ${types.join(', ')} files are accepted.`,'messagetype':'error'});
					//remove the chosen file
					this.initialUploaded[fileType].splice(0)
        } else {
					//accept the chosen file
					this.initialUploaded[fileType] = newFiles
        }
			}
	},
	//LINK TO PRP ASSESSMENT TOOL
  redirectToExternalLink() {
    window.open('https://docs.google.com/spreadsheets/d/1kcu8gs7st3P-6ynDVmgwONMyYPmqHhI3wfhMW0c0mxI/edit?gid=981726778#gid=981726778', '_blank');
  },
}
};
</script>
