<template>
    <div>
        <v-row>
            <v-col :cols="3">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.business_permit.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="3">
                <v-menu
                    v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.date_issued"
                            label="Date Issued"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                           v-on="!ReAccreditationDocuments.cooperative_hybrid.business_permit.file  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.date_issued"
                        @input="ReAccreditationDocuments.cooperative_hybrid.business_permit.menuIssued = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col :cols="3">
                <v-menu
                    v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.menuExpiry"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.date_expiry"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="!ReAccreditationDocuments.cooperative_hybrid.business_permit.date_issued  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="ReAccreditationDocuments.cooperative_hybrid.business_permit.date_expiry"
                        @input="ReAccreditationDocuments.cooperative_hybrid.business_permit.menuExpiry = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.business_permit">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="retrieveUploaded(existingUploadedFiles.business_permit)"
                            >
                            mdi-eye
                        </v-icon>
                    </template>
                    <span v-if="existingUploadedFiles.business_permit">Date Issued: {{returnDateIssued(existingUploadedFiles.business_permit).date_issued}}</span><br>
                    <span v-if="existingUploadedFiles.business_permit">Expiry Date: {{returnDateIssued(existingUploadedFiles.business_permit).date_expiry}}</span><br>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.cda.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.cda.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.cda">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.cda)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>


        <v-row>
            <v-col :cols="6">
                <v-file-input
                    v-model="documents.cooperative_hybrid.cmap.file"
                    :label="documents.cooperative_hybrid.cmap.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.cmap">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.cmap)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
            <v-col :cols="2" v-if="currUser.user_role === 'encoder' && !disabled">
                <v-btn 
                    text
                    color="red"
                    small
                    >
                    <CmapRequest/>
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.secretary_certificate.file"
                    :label="documents.cooperative_hybrid.secretary_certificate.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.secretary_certificate">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.secretary_certificate)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>



        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.bir_cor.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.bir_cor.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.bir_cor">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.bir_cor)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.supplier_reference.file"
                    :label="documents.cooperative_hybrid.supplier_reference.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.supplier_reference">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.supplier_reference)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.org_chart.file"
                    :label="documents.cooperative_hybrid.org_chart.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.org_chart">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.org_chart)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.board_resolution.file"
                    :label="documents.cooperative_hybrid.board_resolution.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.board_resolution">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.board_resolution)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.fss_deed_of_undertaking.file"
                    :label="documents.cooperative_hybrid.fss_deed_of_undertaking.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled || reaccreditation"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.fss_deed_of_undertaking">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.fss_deed_of_undertaking)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.fss_pdaf.file"
                    :label="documents.cooperative_hybrid.fss_pdaf.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled || reaccreditation"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.fss_pdaf">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.fss_pdaf)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.fs_itr.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.fs_itr.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.fs_itr">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.fs_itr)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-checkbox
                    class="mb-5"
                    label="Western Union"
                    color="indigo"
                    hide-details="auto"
                    v-model="ReAccreditationDocuments.is_western_union"
                    :readonly='disabled'
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="ReAccreditationDocuments.is_western_union == true">
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.mother_of_contract.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.mother_of_contract.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.mother_of_contract">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.mother_of_contract)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-checkbox
                    class="mb-5"
                    label="AVP MOA"
                    color="indigo"
                    hide-details="auto"
                    v-model="ReAccreditationDocuments.is_avp"
                    :readonly='disabled'
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="ReAccreditationDocuments.is_avp == true">
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.moa_avp.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.moa_avp.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    :disabled="disabled"
                    hide-details="auto"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.moa_avp">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.moa_avp)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-checkbox
                    class="mb-5"
                    label="NONEX MOA"
                    color="indigo"
                    hide-details="auto"
                    v-model="ReAccreditationDocuments.is_nonex"
                    :readonly='disabled'
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="ReAccreditationDocuments.is_nonex == true">
            <v-col :cols="9">
                <v-file-input
                    v-model="ReAccreditationDocuments.cooperative_hybrid.moa_nonex.file"
                    :label="ReAccreditationDocuments.cooperative_hybrid.moa_nonex.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    :disabled="disabled"
                    hide-details="auto"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.moa_nonex">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.moa_nonex)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <v-file-input
                    v-model="documents.cooperative_hybrid.amla_questionnaire.file"
                    :label="documents.cooperative_hybrid.amla_questionnaire.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    :disabled="disabled || reaccreditation"
                    hide-details="auto"
                    @change="inputFile"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.amla_questionnaire">
                <v-icon
                    @click="retrieveUploaded(existingUploadedFiles.amla_questionnaire)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>

        <v-row v-if="showAmlaCor">
            <v-col :cols="3">
                <v-file-input

                    v-model="documents.cooperative_hybrid.amla_cor.file"
                    :label="documents.cooperative_hybrid.amla_cor.name"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="disabled || reaccreditation"
                    @change="inputFile"
                    
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="3">
                <v-menu
                    v-model="documents.cooperative_hybrid.amla_cor.menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="documents.cooperative_hybrid.amla_cor.date_issued"
                            label="Date Issued"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="!documents.cooperative_hybrid.amla_cor.file  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled="disabled || reaccreditation"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="documents.cooperative_hybrid.amla_cor.date_issued"
                        @input="documents.cooperative_hybrid.amla_cor.menuIssued = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col :cols="3">
                <v-menu
                    v-model="documents.cooperative_hybrid.amla_cor.menuExpiry"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="documents.cooperative_hybrid.amla_cor.date_expiry"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="!documents.cooperative_hybrid.amla_cor.date_issued  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled="disabled || reaccreditation"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="documents.cooperative_hybrid.amla_cor.date_expiry"
                        @input="documents.cooperative_hybrid.amla_cor.menuExpiry = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingUploadedFiles.amla_cor">
                <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="retrieveUploaded(existingUploadedFiles.amla_cor)"
                        >
                        mdi-eye
                    </v-icon>
                </template>
                <span v-if="existingUploadedFiles.amla_cor">Date Issued: {{returnDateIssued(existingUploadedFiles.amla_cor).date_issued}}</span><br>
                <span v-if="existingUploadedFiles.amla_cor">Expiry Date: {{returnDateIssued(existingUploadedFiles.amla_cor).date_expiry}}</span><br>
                </v-tooltip>
            </v-col>
        </v-row>


    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CmapRequest from '../../../components/CmapRequest.vue';
export default {
    props: ['disabled', 'reaccreditation'],
    data(){
        return{
            showAmlaCor: false,
            showAmlaQuestionnaire: false,
            financialBusinessType : [
                'Bank',
                'Financial Services',
                'Currency Exchange',
                'Money Lender',
                'Pawnshop',
                'Money Transmitter',
                'Cash in Services'
            ]
        }
    },
    watch:{
        'otherPrimaryDetails.business_type_of_sub_agents': function(newVal, oldVal) {
            console.log(oldVal)
            if (Object.keys(newVal).length === 0){
                this.showAmlaCor = false
            } else {
                for (let i = 0; i < newVal.length; i++) {
                    if (this.financialBusinessType.includes(newVal[i])) {
                        this.showAmlaCor = true
                        break
                    } else {
                        this.showAmlaCor = false
                    }
                }
            }
        },
    },
    components:{
        CmapRequest: CmapRequest
    },
    computed: {
		...mapGetters({
			documents: 'salesProspecting/documents',
            existingUploadedFiles: 'salesProspecting/existingUploadedFiles',
            currUser: 'auth/currUser',
            otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
            ReAccreditationDocuments: 'salesProspecting/ReAccreditationDocuments'
		}),
	},
    mounted(){
        if (this.otherPrimaryDetails.business_type_of_sub_agents){
            let businessType = this.otherPrimaryDetails.business_type_of_sub_agents
            for (let i = 0; i < businessType.length; i++) {
                if (this.financialBusinessType.includes(businessType[i])) {
                    this.showAmlaCor = true
                    break
                }
            }
        }
    },
    methods: {
        returnDateIssued (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            return max
        },
        //VIEW OF UPLOADED FILES
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
        inputFile(file){
            if (file !== null){
                let payload = true
                this.$store.commit('salesProspecting/SET_SINGLE_UPLOADED', payload)
            } else {
                let payload = false
                this.$store.commit('salesProspecting/SET_SINGLE_UPLOADED', payload)
            }
        },
    },
};
</script>
