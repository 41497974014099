<template>
   
    <div class="mb-5">
        <v-expansion-panels class="mb-5">
            <v-expansion-panel v-for="(parentItem, parentIndex) in existingUploadedBranches" :key="parentIndex">
                <v-expansion-panel-header>
                    <div>
                        <v-icon :disabled="disabled" @click.stop="" @click="deleteBranch(parentItem)" color="error" y>mdi-delete</v-icon>
                        <!-- <span class="mx-2">Branch {{parentIndex+1}}</span> -->
                        &ensp;
                        <span v-if="parentItem.is_draft == true">{{parentItem.branch_name}} (Draft) </span>
                        <span v-if="parentItem.is_draft == false">{{parentItem.branch_name}}</span>
                    </div>
                </v-expansion-panel-header>
                <v-form ref="uploadedBranch" v-model="uploadedBranchForm">
                    <v-expansion-panel-content eager>
                        <div class="mt-1"> 
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="*Branch Name"
                                        hide-details="auto"
                                        v-model="parentItem.branch_name"
                                        outlined
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="*Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_1"
                                        outlined
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="*Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_2"
                                        outlined
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        label="*Country"
                                        v-model="parentItem.country"
                                        item-text="country"
                                        item-value="country"
                                        outlined
                                        hide-details="auto"
                                        :rules="existingGenericRule"
                                        dense
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        v-if="disabled == true"
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        :readonly="disabled || reaccreditation"
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        @change="getRegion"
                                        @click="getProvinceIndex(parentIndex)"
                                        :items="province"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingBranchesCity[parentIndex]"
                                        label="*City"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*City"
                                        outlined
                                        no-filter
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        :items="existingBranchesCity[parentIndex]"
                                        @change="getZip"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="*Region"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.region"
                                        dense
                                        readonly
                                        :rules="existingGenericRule"
                                        >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingBranchesZip[parentIndex]"
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        :items="existingBranchesZip[parentIndex]"
                                        >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Landline Number"
                                        v-model="parentItem.landline"
                                        outlined
                                        hide-details="auto"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="*Cellphone Number"
                                        v-model="parentItem.cellphone"
                                        outlined
                                        hide-details="auto"
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="*Email"
                                        v-model="parentItem.email_address"
                                        outlined
                                        hide-details="auto"
                                        :rules="existingEmailRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <h4>Mailing Address</h4>
                                </v-col>
                            </v-row>

                            <v-checkbox
                                label="Same As Primary Address"
                                v-model="parentItem.is_same_primary"
                                @click="sameAddress(parentItem)"
                                :disabled="checkAddress() || disabled || reaccreditation"
                                >
                            </v-checkbox>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Mailing Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.mailing_address_line_1"
                                        outlined
                                        dense
                                        :disabled="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Mailing Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.mailing_address_line_2"
                                        outlined
                                        dense
                                        :disabled="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        label="Mailing Country"
                                        readonly
                                        item-text="country"
                                        item-value="country"
                                        v-model="parentItem.mailing_country"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        v-if="disabled == true"
                                        label="Mailing Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_province"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="Mailing Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_province"
                                        dense
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        @change="getRegionMailing"
                                        @click="getProvinceIndexMailing(parentIndex)"
                                        :items="province"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true ||  !existingBranchesCityMailing[parentIndex]"
                                        label="Mailing City"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_city"
                                        dense
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="Mailing City"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_city"
                                        dense
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        :items="existingBranchesCityMailing[parentIndex]"
                                        @change="getZipMailing"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="Mailing Region"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_region"
                                        dense
                                        readonly
                                        >
                                    </v-text-field>
                                </v-col>

                                 <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingBranchesZipMailing[parentIndex]"
                                        label="Mailing Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="Mailing Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.mailing_postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        :items="existingBranchesZipMailing[parentIndex]"
                                        >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Mailing Landline Number"
                                        v-model="parentItem.mailing_landline"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons
                                        :disabled="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Mailing Cellphone Number"
                                        v-model="parentItem.mailing_cellphone"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons
                                        :disabled="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Mailing Email"
                                        v-model="parentItem.mailing_email_address"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-autocomplete
                                        label="Designated Personnel Name"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        hide-spin-buttons
                                        :readonly="disabled || reaccreditation"
                                        :items="users"
                                        item-text="name"
                                        return-object
                                        v-model="parentItem.handler_name"
                                        @change="getHandlerEmail"
                                        @click="getIndex(parentIndex)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Designated Personnel Email"
                                        v-model="parentItem.handler_email"
                                        outlined
                                        readonly
                                        hide-details="auto"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="TID"
                                        v-model="parentItem.tid"
                                        outlined
                                        hide-details="auto"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="FTID"
                                        v-model="parentItem.ftid"
                                        outlined
                                        hide-details="auto"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="APZ"
                                        v-model="parentItem.apz"
                                        outlined
                                        hide-details="auto"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="API"
                                        v-model="parentItem.api"
                                        outlined
                                        hide-details="auto"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            
                            <v-row v-if="parentItem.businessPermitUploaded">
                                <v-col :cols="3">
                                    <v-file-input
                                        v-model="parentItem.business_permit.file"
                                        label="*Business Permit"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.business_permit.menuIssued"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.business_permit.date_issued"
                                                label="*Date Issued"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.business_permit.file  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="checkRuleBP(parentItem)"
                                                :disabled="disabled || reaccreditation"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.business_permit.date_issued"
                                            @input="parentItem.business_permit.menuIssued = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.business_permit.menuExpiry"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.business_permit.date_expiry"
                                                label="*Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.business_permit.date_issued  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="checkRuleBP(parentItem)"
                                                :disabled="disabled || reaccreditation"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.business_permit.date_expiry"
                                            @input="parentItem.business_permit.menuExpiry = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.businessPermitUploaded">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="retrieveUploaded(parentItem.businessPermitUploaded)"
                                                >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span v-if="parentItem.businessPermitUploaded">Date Issued: {{returnDateIssued(parentItem.businessPermitUploaded).date_issued}}</span><br>
                                        <span v-if="parentItem.businessPermitUploaded">Expiry Date: {{returnDateIssued(parentItem.businessPermitUploaded).date_expiry}}</span><br>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col :cols="3">
                                    <v-file-input
                                        v-model="parentItem.business_permit.file"
                                        label="*Business Permit"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        :rules="existingFileRUle"
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.business_permit.menuIssued"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.business_permit.date_issued"
                                                label="*Date Issued"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.business_permit.file  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="checkRuleBP(parentItem)"
                                                :disabled="disabled || reaccreditation"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.business_permit.date_issued"
                                            @input="parentItem.business_permit.menuIssued = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.business_permit.menuExpiry"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.business_permit.date_expiry"
                                                label="*Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.business_permit.date_issued  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="checkRuleBP(parentItem)"
                                                :disabled="disabled || reaccreditation"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.business_permit.date_expiry"
                                            @input="parentItem.business_permit.menuExpiry = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.businessPermitUploaded">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="retrieveUploaded(parentItem.businessPermitUploaded)"
                                                >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span v-if="parentItem.businessPermitUploaded">Date Issued: {{returnDateIssued(parentItem.businessPermitUploaded).date_issued}}</span><br>
                                        <span v-if="parentItem.businessPermitUploaded">Expiry Date: {{returnDateIssued(parentItem.businessPermitUploaded).date_expiry}}</span><br>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col :cols="4">
                                    <v-text-field
                                        label="*FLA with User Access"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        v-model="parentItem.fla_user_access"
                                        type="number"
                                        hide-spin-buttons
                                        :rules="existingGenericRule"
                                        :readonly="disabled || reaccreditation"
                                        @change="generate"
                                    ></v-text-field>
                                </v-col>

                                <v-col :cols="4" v-if="parentItem.justificationUploaded">
                                    <v-file-input
                                        v-if="parseInt(parentItem.fla_user_access) > 5"
                                        label="*Justification"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                        v-model="parentItem.justification.file"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="4" v-else>
                                    <v-file-input
                                        v-if="parseInt(parentItem.fla_user_access) > 5"
                                        label="*Justification"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled || reaccreditation"
                                        v-model="parentItem.justification.file"
                                        :rules="existingFileRUle"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.justificationUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.justificationUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col
                                    cols="6"
                                    sm="6"
                                    md="6"
                                    >
                                    <v-text-field
                                        label="*Location Code"
                                        v-model="parentItem.location_code"
                                        outlined
                                        type="number"
                                        hide-spin-buttons
                                        hide-details="auto"
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                    sm="6"
                                    md="6"
                                    >
                                    <v-autocomplete
                                        label="*Class Code"
                                        v-model="parentItem.class_code_branch"
                                        outlined
                                        hide-details="auto"
                                        :rules="existingGenericRule"
                                        dense
                                        :readonly="disabled || reaccreditation"
                                        :items="items"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>


                    <br>
                        <v-row class="mt-2">
                            <v-col cols="4">
                                <GmapAutocomplete 
                                class="searchLocation"
                                placeholder="Enter Place"
                                :disabled="disabled || reaccreditation"
                                @place_changed='setPlace'
                                :value="addressInput"
                                style="height: 40px; width: 100%; min0"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-btn class="mb-4" :style="{'float': 'right'}" @click="addMarker(parentIndex)" block outlined color="primary" height="40px" :disabled="disabled">Set Place</v-btn>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="parentItem.coordinates.lat"
									label="*Latitude"
                                    readonly
                                    outlined
                                    dense
                                    :rules="existingGenericRule"
								>
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="parentItem.coordinates.lng"
									label="*Longtitude"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                    :rules="existingGenericRule"
								>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <section>
                                    <GmapMap
                                        v-if="typeof parentItem.coordinates.lat == 'number' && !isNaN(parentItem.coordinates.lat)"
                                        :center='parentItem.coordinates'
                                        :zoom='12'
                                        :style="{ width: '100%', height: '400px' }"
                                    >
                                        <!-- <GmapMarker
                                            :key="index"
                                            v-for="(m, index) in markers"
                                            :position="m.position"
                                            @click="center=m.position"
                                        /> -->
                                        <GmapMarker
                                            :position="parentItem.coordinates"
                                        />
                                    </GmapMap>
                                    <GmapMap
                                        v-else
                                        :center='center'
                                        :zoom='12'
                                        :style="{ width: '100%', height: '400px' }"
                                    ></GmapMap>
                                </section>
                            </v-col>
                        </v-row>



                            <v-row>
                                <v-col cols="12">
                                    <h4>Operation Hours</h4>
                                </v-col>
                                <v-col cols="12">
                                    <v-card elevation="0">
                                        <v-tabs
                                            v-model="tab"
                                            >
                                            <v-tab
                                                v-for="day in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <b>{{ day.day }}</b>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(day) in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <v-card flat>
                                                    <v-row class="my-3">
                                                        <v-col cols="4" v-if="day.isClosed === 0">
                                                            <vue-timepicker append-to-body format="hh:mm A" v-model="day.timeStart" placeholder="*Operating hours from" input-width="300px" :disabled="disabled"></vue-timepicker>
                                                        </v-col>
                                                        <v-col cols="4"  v-if="day.isClosed === 0">
                                                            <vue-timepicker append-to-body format="hh:mm A" v-model="day.timeEnd" placeholder="*Operating hours to" input-width="300px" :disabled="disabled"></vue-timepicker>
                                                        </v-col>
                                                        <v-col cols="3" class="my-0 py-0">
                                                            <v-checkbox
                                                                v-model="day.isClosed"
                                                                label="Closed"
                                                                :false-value="0"
                                                                :true-value="1"
                                                                :readonly="disabled || reaccreditation"
                                                            ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-5">
                            <v-btn class="mb-4" :style="{'float': 'right'}" @click="addFLA(parentIndex)" v-if="!disabled" outlined color="primary"><v-icon>mdi-plus</v-icon>Add FLA</v-btn>
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(fla, index) in parentItem.fla" :key="index">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon :disabled="disabled" @click.stop="" @click="deleteFLA(fla)" color="error" v-if="index > 0 ? 'mdi-delete' : ''">mdi-delete</v-icon>
                                            <!-- <v-icon @click.stop="" @click="deleteFLA(fla)" color="error" y>mdi-delete</v-icon> -->
                                            <span class="mx-2">FLA {{index+1}}</span>
                                            <span v-if="fla.last_name != '' && fla.first_name != ''"> {{ fla.last_name }}, {{fla.first_name}}</span>
                                            <span :style="{ 'font-weight': 'bold' }" v-if="fla.fla_status != ''"> ({{ fla.fla_status }})</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content eager>
                                        <v-row class="mb-3">
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="*First Name"
                                                    hide-details="auto"
                                                    v-model="fla.first_name"
                                                    outlined
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="*Middle Name"
                                                    hide-details="auto"
                                                    v-model="fla.middle_name"
                                                    outlined
                                                    dense
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="*Last Name"
                                                    hide-details="auto"
                                                    v-model="fla.last_name"
                                                    outlined
                                                    dense
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="Suffix"
                                                    hide-details="auto"
                                                    v-model="fla.suffix"
                                                    outlined
                                                    dense
                                                    :readonly="disabled"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                         <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Email"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.email_address"
                                                :readonly="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Citizenship"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.citizenship"
                                                readonly
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="6">
                                            <v-menu
                                                v-model="fla.menuExpiry"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="fla.date_of_seminar"
                                                        label="Date of AMLA Seminar Attended"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        dense
                                                        outlined
                                                        hide-details="auto"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="fla.date_of_seminar"
                                                    @input="fla.menuExpiry = false"
                                                    :disabled="disabled"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Tin No."
                                                outlined
                                                dense
                                                v-model="fla.tin_no"
                                                hide-details="auto"
                                                :readonly="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Tel No."
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.tel_no"
                                                :readonly="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Fax No."
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.fax_no"
                                                :readonly="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Address"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.address"
                                                :readonly="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-checkbox
                                                    label="WUPOS OP ID"
                                                    color="indigo"
                                                    v-model="fla.is_wupos"
                                                    hide-details="auto"
                                                    class="mb-5"
                                                    :readonly="disabled"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    v-if="fla.is_wupos"
                                                    label="*WUPOS OP ID"
                                                    hide-details="auto"
                                                    v-model="fla.wupos_op_id"
                                                    outlined
                                                    dense
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-checkbox
                                                    label="USP OP ID"
                                                    color="indigo"
                                                    v-model="fla.is_usp"
                                                    hide-details="auto"
                                                    class="mb-5"
                                                    :readonly="disabled"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    v-if="fla.is_usp"
                                                    label="*USP OP ID"
                                                    hide-details="auto"
                                                    v-model="fla.usp_op_id"
                                                    outlined
                                                    dense
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="6">
                                                <v-autocomplete
                                                    label="*Status"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    hide-spin-buttons
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                    :items="status"
                                                    v-model="fla.fla_status"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="*Agent Code"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    hide-spin-buttons
                                                    :readonly="disabled"
                                                    :rules="existingGenericRule"
                                                    v-model="fla.agent_code"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col>
                                                <b>Compliance Requirements</b>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="fla.amlaUploaded">
                                            <v-col :cols="3">
                                                <v-file-input
                                                    v-model="fla.amla.file"
                                                    label="*AMLA"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="3">
                                                <v-menu
                                                    v-model="fla.amla.menuIssued"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                    hide-details="auto"
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="fla.amla.date_issued"
                                                            label="*Date Issued"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="!fla.amla.file  ? '' : on"
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                            :disabled="disabled"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fla.amla.date_issued"
                                                        @input="fla.amla.menuIssued = false"
                                                        hide-details="auto"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col :cols="3">
                                                <v-menu
                                                    v-model="fla.amla.menuExpiry"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                    hide-details="auto"
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="fla.amla.date_expiry"
                                                            label="*Expiry Date"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="!fla.amla.date_issued  ? '' : on"
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                            :disabled="disabled"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fla.amla.date_expiry"
                                                        @input="fla.amla.menuExpiry = false"
                                                        hide-details="auto"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col :cols="1" class="mt-1" v-if="fla.amlaUploaded">
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="retrieveUploaded(fla.amlaUploaded)"
                                                            >
                                                            mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span v-if="fla.amlaUploaded">Date Issued: {{returnDateIssued(fla.amlaUploaded).date_issued}}</span><br>
                                                    <span v-if="fla.amlaUploaded">Expiry Date: {{returnDateIssued(fla.amlaUploaded).date_expiry}}</span><br>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col :cols="3">
                                                <v-file-input
                                                    v-model="fla.amla.file"
                                                    label="*AMLA"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="existingFileRUle"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="3">
                                                <v-menu
                                                    v-model="fla.amla.menuIssued"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                    hide-details="auto"
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="fla.amla.date_issued"
                                                            label="*Date Issued"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="!fla.amla.file  ? '' : on"
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                            :rules="existingGenericRule"
                                                            :disabled="disabled"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fla.amla.date_issued"
                                                        @input="fla.amla.menuIssued = false"
                                                        hide-details="auto"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col :cols="3">
                                                <v-menu
                                                    v-model="fla.amla.menuExpiry"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                    hide-details="auto"
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="fla.amla.date_expiry"
                                                            label="*Expiry Date"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="!fla.amla.date_issued  ? '' : on"
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                            :rules="existingGenericRule"
                                                            :disabled="disabled"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fla.amla.date_expiry"
                                                        @input="fla.amla.menuExpiry = false"
                                                        hide-details="auto"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col :cols="1" class="mt-1" v-if="fla.amlaUploaded">
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="retrieveUploaded(fla.amlaUploaded)"
                                                            >
                                                            mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span v-if="fla.amlaUploaded">Date Issued: {{returnDateIssued(fla.amlaUploaded).date_issued}}</span><br>
                                                    <span v-if="fla.amlaUploaded">Expiry Date: {{returnDateIssued(fla.amlaUploaded).date_expiry}}</span><br>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col :cols="9" v-if="fla.nbiUploaded">
                                                <v-file-input
                                                    v-model="fla.nbi.file"
                                                    label="*NBI Clearance"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="9" v-else>
                                                <v-file-input
                                                    v-model="fla.nbi.file"
                                                    label="*NBI Clearance"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="existingFileRUle"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="1" class="mt-1" v-if="fla.nbiUploaded">
                                                <v-icon
                                                    @click="retrieveUploaded(fla.nbiUploaded)"
                                                    >
                                                    mdi-eye
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col :cols="9" v-if="fla.valid_idUploaded">
                                                <v-file-input
                                                    v-model="fla.valid_id.file"
                                                    label="*Valid ID"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="9" v-else>
                                                <v-file-input
                                                    v-model="fla.valid_id.file"
                                                    label="*Valid ID"
                                                    prepend-icon="mdi-paperclip"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="existingFileRUle"
                                                    :disabled="disabled"
                                                    >
                                                </v-file-input>
                                            </v-col>
                                            <v-col :cols="1" class="mt-1" v-if="fla.valid_idUploaded">
                                                <v-icon
                                                    @click="retrieveUploaded(fla.valid_idUploaded)"
                                                    >
                                                    mdi-eye
                                                </v-icon>
                                            </v-col>
                                        </v-row> -->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                            <v-row class="mt-2">


                                <!-- NOT DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == false">
                                    <v-btn v-if="!disabled" outlined color="primary" :style="{'float': 'right'}" @click="updateBranch(parentItem, parentIndex)">
                                        Update Branch
                                    </v-btn>
                                </v-col>

                                <!-- DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == true">
                                    <v-btn v-if="!disabled" class="ml-3" color="primary" :style="{'float': 'right'}" @click="updateBranch(parentItem, parentIndex)">
                                        Add Branch
                                    </v-btn>
                                    <v-btn v-if="!disabled" outlined color="primary" :style="{'float': 'right'}" @click="updateDraft(parentItem, parentIndex)">
                                        Update Draft
                                    </v-btn>
                                </v-col>



                            </v-row>
                    </v-expansion-panel-content>
                </v-form>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog
            v-model="deleteBranchDialog"
            persistent
            max-width="400"
            >
            <v-card>
                <v-card-title>
                    Delete {{dialogTitle}}
                </v-card-title>
                <v-card-text>
                    <br>
                    <p :style="{ fontSize: '15px' }">Delete {{dialogDescription}}? You can't undo this.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="cancelDialog"
                        >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        @click="confirmDialog"
                        >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import _ from 'lodash';
export default {
    props: ['disabled','reaccreditation'],
    components: {
        VueTimepicker: VueTimepicker
    },
    data: () => ({ 
        items: ["Send and Receive", "Send Only", "Receive Only"],
        status:['Active','Separated'],
        center: { lat: 14.5528063, lng: 121.0167313 },
        currentPlace: null,
        addressInput: '',
        showUpload: null,
        item: '',
        tab: null,
        businessPermitRule: [],
        amlaRule: [],
        drugTestRule: [],
        nbiRule: [],
        uploadedBranchForm: true,
        localGenericRule: [],
        currentIndex: '',
        handler_index: '',
        deleteBranchDialog: false,
        branchId: '',
        dialogTitle: '',
        dialogDescription: '',
        flaId: '',
        currentIndexMailing : ''
    }),
    mounted(){
        this.geolocate();
    },
    methods: {
        checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
				return false
			} else {
				return true
			}
		},
        sameAddress (item) {
			if (item.is_same_primary){
				console.log('check')
				item.is_same_primary = true
				item.mailing_address_line_1 = this.primaryBusinessAddress.address_line_1
				item.mailing_address_line_2 = this.primaryBusinessAddress.address_line_2
				item.mailing_country = this.primaryBusinessAddress.country
				item.mailing_city = this.primaryBusinessAddress.city
				item.mailing_region = this.primaryBusinessAddress.region
				item.mailing_postal_code = this.primaryBusinessAddress.postal_code
				item.mailing_province = this.primaryBusinessAddress.province
				item.mailing_landline = this.primaryBusinessAddress.landline
				item.mailing_cellphone = this.primaryBusinessAddress.cellphone
			} else {
				console.log('uncheck')
				item.mailing_address_line_1 = ''
				item.mailing_address_line_2 = ''
				item.mailing_country = 'Philippines'
				item.mailing_city = ''
				item.mailing_province = ''
				item.mailing_region = ''
				item.mailing_postal_code = ''
				item.mailing_landline = ''
				item.mailing_cellphone = ''
			}
		},
        async getRegionMailing(province){
            console.log(this.existingUploadedBranches[this.currentIndexMailing])
			this.existingUploadedBranches[this.currentIndexMailing].mailing_postal_code = ''
			this.existingUploadedBranches[this.currentIndexMailing].mailing_city = ''
			const payload = {
				payload : province,
				token : this.hrisToken,
                index: this.currentIndexMailing
			}
        await this.$store.dispatch('salesProspecting/doGetExistingBranchesRegionMailing', {payload})

			const finalPayload = {
				payload : this.existingUploadedBranches[this.currentIndexMailing].mailing_region,
				token : this.hrisToken,
                index: this.currentIndexMailing
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBranchesCityMailing', {finalPayload})
		},
        async getZipMailing(city){
			const payload = {
				payload : this.existingUploadedBranches[this.currentIndexMailing].mailing_region,
				city : city,
				token: this.hrisToken,
                index: this.currentIndexMailing
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBranchesZipMailing', {payload})
		},
        getProvinceIndexMailing(index){
            this.currentIndexMailing = index
        },
        getIndex(i){
            this.handler_index = i
        },
        getHandlerEmail(i){
            this.existingUploadedBranches[this.handler_index].handler_name = i.name
            this.existingUploadedBranches[this.handler_index].handler_email = i.email
        },
        getProvinceIndex(index){
            this.currentIndex = index
        },
        async getRegion(province){
			this.existingUploadedBranches[this.currentIndex].postal_code = ''
			this.existingUploadedBranches[this.currentIndex].city = ''
			const payload = {
				payload : province,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBranchesRegion', {payload})

			const finalPayload = {
				payload : this.existingUploadedBranches[this.currentIndex].region,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBranchesCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.existingUploadedBranches[this.currentIndex].region,
				city : city,
				token: this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingBranchesZip', {payload})
		},

        async deleteBranch(item){
            this.dialogTitle = 'Branch'
            this.dialogDescription = item.branch_name
            this.branchId = item.branch_id
            this.deleteBranchDialog = true
        },
        async confirmDialog (){
            const payload = {id: this.$route.params.id, branch_id: this.branchId}
            if (this.dialogTitle == 'Branch'){
                const res = await this.$store.dispatch('salesProspecting/doDeleteBranch', {payload})
                if (res.status == 200){
                    this.dialogTitle = ''
                    this.dialogDescription = ''
                    this.branchId = ''
                    this.flaId = ''
                    this.deleteBranchDialog = false
                    this.reloadBranches()
                }
            } else if (this.dialogTitle == 'FLA'){
                const payload = {id: this.$route.params.id, fla_id: this.flaId}
                const res = await this.$store.dispatch('salesProspecting/doDeleteFla', {payload})
                if (res.status == 200){
                    this.dialogTitle = ''
                    this.dialogDescription = ''
                    this.branchId = ''
                    this.flaId = ''
                    this.deleteBranchDialog = false
                    this.reloadBranches()
                }
            }
            
        },
        cancelDialog(){
            this.dialogTitle = ''
            this.dialogDescription = ''
            this.deleteBranchDialog = false
            this.branchId = ''
            this.flaId = ''
        },
        async deleteFLA(item){
            this.dialogTitle = 'FLA'
            this.dialogDescription = item.first_name
            this.flaId = item.id
            this.deleteBranchDialog = true
        },
        generate(value){
            let newValue =  (parseInt(value))
            if (newValue >= 5){
                this.showUpload = true
            } else {
                this.showUpload = false
            }
        },
        setPlace(place) {
            this.currentPlace = place;
            this.addressInput = ''
        },
        addMarker(parentIndex) {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.center = marker;
                this.currentPlace = null;
                const payload = {
                    lat: marker.lat,
                    lng: marker.lng,
                    index: parentIndex
                }
                this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_COORDINATES',{payload})
            }
        },
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(geolocation => {
            this.center = {
                lat: geolocation.coords.latitude,
                lng: geolocation.coords.longitude
            };
            }); 
        },
        checkRuleBP (item) {
            if (item.business_permit.file) {
                return [v => !!v || 'This field is required']
            } else {
                return []
            }
        },
        checkRule (item, property) {
            if (item[property].file) {
                return [v => !!v || 'This field is required']
            } else if (item.fla_id === 'null') {
                return [v => !!v || 'This field is required']
            }
        },
        addFLA (parentIndex) {
            this.existingUploadedBranches[parentIndex].fla.push(
                {
                    fla_id: 'null',
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    suffix: '',
                    wupos_op_id: '',
                    usp_op_id: '',
                    fla_status:'',
                    amla: {
                        file: null,
                        date_issued: '',
                        date_expiry: '',
                        menuIssued: false,
                        menuExpiry: false
                    },
                    nbi: {
                        file: null,
                    },
                    // valid_id: {
                    //     file: null,
                    // },
                    email_address: '',
                    citizenship: 'Filipino',
                    date_of_seminar: '',
                    tin_no: '',
                    tel_no: '',
                    fax_no: '',
                    is_wupos: null,
                    is_usp: null,
                    menuExpiry: false
                }
            )
        },
        returnDateIssued (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            return max
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
        updateDraft(item){

            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            }

            var initialPayload = {...item, is_draft: true}
            console.log(initialPayload)
            delete initialPayload.merchant_data
            delete initialPayload.id
            delete initialPayload.created_at
            delete initialPayload.updated_at
            delete initialPayload.businessPermitUploaded
            delete initialPayload.justificationUploaded
            delete initialPayload.justificationUploaded
            console.log(initialPayload)
            initialPayload.fla.forEach(function (e) {
                if(e.id) { 
                    e.fla_id = e.id
                }
                if (e.nbiUploaded) {
                    delete e.nbiUploaded
                }
                if (e.drugTestUploaded) {
                    delete e.drugTestUploaded
                }
                if (e.amlaUploaded) {
                    delete e.amlaUploaded
                }
                // if (e.valid_idUploaded) {
                //     delete e.valid_idUploaded
                // }
                delete e.created_at
                delete e.updated_at
                delete e.menuExpiry
            })
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            this.callUpdateBranch(payload)
        },
        updateBranch (item, index) {
            const self = this
            
            self.$store.commit('salesProspecting/SET_RULE_FILE_EXISTING')
            self.$store.commit('salesProspecting/SET_RULE_GENERIC_EXISTING')
			self.$store.commit('salesProspecting/SET_RULE_EMAIL_EXISTING')

			setTimeout(() => {
				var errDays = []
				item.operating_hours.forEach(function (e) {
					if (!e.isClosed && (e.timeStart === '' || e.timeEnd === '')) {
						errDays.push(e)
					}
				})
				if (errDays.length == 0){
                    if (item.fla.length != 0){
                        if (self.$refs.uploadedBranch[index].validate()) {
                            var options = {
                                initialFormData: new FormData(),
                                howLeafArrayIndexes: true,
                                includeNullValues: false,
                                mapping: function(value) {
                                    if (typeof value === 'boolean') {
                                        return +value ? '1': '0';
                                    }
                                    return value;
                                }
                            }
                            var initialPayload = {...item, is_draft: false}
                            console.log(initialPayload)
                            delete initialPayload.merchant_data
                            delete initialPayload.id
                            delete initialPayload.created_at
                            delete initialPayload.updated_at
                            delete initialPayload.businessPermitUploaded
                            delete initialPayload.justificationUploaded
                            delete initialPayload.justificationUploaded
                            console.log(initialPayload)
                            initialPayload.fla.forEach(function (e) {
                                if(e.id) { 
                                    e.fla_id = e.id
                                }
                                if (e.nbiUploaded) {
                                    delete e.nbiUploaded
                                }
                                if (e.drugTestUploaded) {
                                    delete e.drugTestUploaded
                                }
                                if (e.amlaUploaded) {
                                    delete e.amlaUploaded
                                }
                                // if (e.valid_idUploaded) {
                                //     delete e.valid_idUploaded
                                // }
                                delete e.created_at
                                delete e.updated_at
                                delete e.menuExpiry
                            })
                            var convertedFormData = jsonToFormData(initialPayload, options)
                            const payload = {id: this.$route.params.id, payload: convertedFormData}
                            this.callUpdateBranch(payload)
                        } else {
                            self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    } else {
                        self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Encode at least 1 FLA!','messagetype':'error'},{ root: true });
                    }
				} else {
					self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Operation hours not filled up yet!','messagetype':'error'},{ root: true });
				}
			})
        },
        async callUpdateBranch (payload) {
            const res = await this.$store.dispatch('salesProspecting/doUpdateBranch', {payload})
            if (res.code == 422 || res.code == 400){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
				this.reloadBranches()
			}
            this.$store.commit('salesProspecting/UNSET_RULE_GENERIC_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_EMAIL_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_FILE_EXISTING')
        },
        async reloadBranches () {
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
            this.$emit('newValue', res.data.result[0].sp_branch)
            this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
            this.decodeBranches(res.data.result[0].sp_branch.data)            
        },
        decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.justificationUploaded = JSON.parse(e.justification)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.justification = _.clone(nonExpiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
                    // f.valid_idUploaded = JSON.parse(f.valid_id)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(nonExpiryFile)
                    // f.valid_id = _.clone(expiryFile)
				})
                e.mailing_country = 'Philippines'
			})
			resBranches.map(coords => {
                if (coords.coordinates.lat){
                    coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                }
                if (coords.coordinates.lng){
                    coords.coordinates.lng = parseFloat(coords.coordinates.lng)
                }
            })

			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			
		},
    },  
    computed: {
		...mapGetters({
            existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
            countries: 'salesProspecting/countries',
            cities: 'salesProspecting/cities',
            existingGenericRule: 'salesProspecting/existingGenericRule',
            existingFileRUle: 'salesProspecting/existingFileRUle',
            existingEmailRule: 'salesProspecting/existingEmailRule',
            branchesCity: 'salesProspecting/branchesCity',
            province: 'salesProspecting/province',
            existingBranchesCity: 'salesProspecting/existingBranchesCity',
            existingBranchesCityMailing: 'salesProspecting/existingBranchesCityMailing',
            existingBranchesZip: 'salesProspecting/existingBranchesZip',
            existingBranchesZipMailing: 'salesProspecting/existingBranchesZipMailing',
            users: 'salesProspecting/users',
            primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
            hrisToken: 'auth/hrisToken'
		}),
	}
};
</script>
<style>
.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
    background: #1A2791;
    color: #fff;
}
html{
   font-family: 'Roboto', sans-serif;
}
</style>