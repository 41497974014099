<template>
    <div class="mt-5">
        <!-- <v-expansion-panels>
            <v-expansion-panel v-for="(item, parentIndex) in branches" :key="parentIndex">
                <v-expansion-panel-header>
                    Branch
                </v-expansion-panel-header>
                <v-expansion-panel-content> -->
        <div class="mt-1">
            <v-row>
                <v-col>
                    <v-text-field label="*Branch Name" hide-details="auto" v-model="branches.branch_name" outlined
                        :disabled="disabled" dense :rules="genericRule"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="*Address Line 1" hide-details="auto" v-model="branches.address_line_1" outlined
                        :disabled="disabled" dense :rules="genericRule"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="*Address Line 2" hide-details="auto" v-model="branches.address_line_2" outlined
                        :disabled="disabled" dense :rules="genericRule"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="2">
                    <v-text-field label="*Country" v-model="branches.country" readonly item-text="country"
                        item-value="country" outlined :disabled="disabled" hide-details="auto" dense
                        :rules="genericRule">
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field v-if="disabled == true" label="*Province" outlined hide-details="auto"
                        v-model="branches.province" dense :rules="genericRule" :readonly="disabled">
                    </v-text-field>
                    <v-autocomplete v-else label="*Province" outlined hide-details="auto" v-model="branches.province"
                        dense :rules="genericRule" hide-spin-buttons :readonly="disabled" @change="getRegion"
                        :items="province">
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                    <v-text-field v-if="disabled == true || branchesCity.length == 0" label="*City" outlined
                        hide-details="auto" v-model="branches.city" dense :rules="genericRule" readonly>
                    </v-text-field>
                    <v-autocomplete v-else label="*City" outlined hide-details="auto" v-model="branches.city" dense
                        :rules="genericRule" hide-spin-buttons :readonly="disabled" :items="branchesCity"
                        @change="getZip">
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="*Region" outlined hide-details="auto" v-model="branches.region" dense readonly
                        :rules="genericRule">
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                    <v-text-field v-if="disabled == true || branchesZip.length == 0" label="*Postal Code" outlined
                        hide-details="auto" v-model="branches.postal_code" dense type="number" :rules="genericRule"
                        readonly>
                    </v-text-field>
                    <v-autocomplete v-else label="*Postal Code" outlined hide-details="auto"
                        v-model="branches.postal_code" dense type="number" :rules="genericRule" hide-spin-buttons
                        :items="branchesZip" :readonly="disabled">
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field label="Landline Number" v-model="branches.landline" outlined hide-details="auto"
                        :disabled="disabled" dense type="number" hide-spin-buttons></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="*Cellphone Number" v-model="branches.cellphone" outlined hide-details="auto"
                        :disabled="disabled" dense type="number" hide-spin-buttons :rules="genericRule"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="*Email" v-model="branches.email_address" outlined hide-details="auto"
                        :disabled="disabled" dense :rules="emailRule"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h4>Mailing Address</h4>
                </v-col>
            </v-row>

            <v-checkbox label="Same As Primary Address" v-model="branches.is_same_primary" @click="sameAddress"
                :disabled="checkAddress() || disabled">
            </v-checkbox>

            <v-row>
                <v-col>
                    <v-text-field label="Mailing Address Line 1" hide-details="auto"
                        v-model="branches.mailing_address_line_1" outlined dense :disabled="disabled"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field label="Mailing Address Line 2" hide-details="auto"
                        v-model="branches.mailing_address_line_2" outlined dense :disabled="disabled"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="2">
                    <v-text-field label="Mailing Country" readonly item-text="country" item-value="country"
                        v-model="branches.mailing_country" outlined dense hide-details="auto" :disabled="disabled">
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field v-if="disabled == true" label="Mailing Province" outlined hide-details="auto"
                        v-model="branches.mailing_province" dense :readonly="disabled">
                    </v-text-field>
                    <v-autocomplete v-else label="Mailing Province" outlined hide-details="auto"
                        v-model="branches.mailing_province" dense hide-spin-buttons :readonly="disabled"
                        @change="getRegionMialing" :items="province">
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                    <v-text-field v-if="disabled == true || branchMailingCity.length == 0" label="Mailing City" outlined
                        hide-details="auto" v-model="branches.mailing_city" dense readonly>
                    </v-text-field>
                    <v-autocomplete v-else label="Mailing City" outlined hide-details="auto"
                        v-model="branches.mailing_city" dense hide-spin-buttons :readonly="disabled"
                        :items="branchMailingCity" @change="getZipMailing">
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="Mailing Region" outlined hide-details="auto" v-model="branches.mailing_region"
                        dense readonly>
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                    <v-text-field v-if="disabled == true || branchMailingZip.length == 0" label="Mailing Postal Code"
                        outlined hide-details="auto" v-model="branches.mailing_postal_code" dense type="number"
                        readonly>
                    </v-text-field>
                    <v-autocomplete v-else label="Mailing Postal Code" outlined hide-details="auto"
                        v-model="branches.mailing_postal_code" dense type="number" hide-spin-buttons
                        :items="branchMailingZip" :readonly="disabled">
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="4">
                    <v-text-field label="Mailing Landline Number" v-model="branches.mailing_landline" outlined dense
                        hide-details="auto" type="number" hide-spin-buttons :disabled="disabled"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Mailing Cellphone Number" v-model="branches.mailing_cellphone" outlined dense
                        hide-details="auto" type="number" hide-spin-buttons :disabled="disabled"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Mailing Email" v-model="branches.mailing_email_address" outlined dense
                        hide-details="auto" :disabled="disabled"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-autocomplete label="Designated Personnel Name" outlined dense hide-details="auto"
                        hide-spin-buttons :disabled="disabled" :items="users" item-text="name" return-object
                        v-model="branches.handler_name" @change="getHandlerEmail"></v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Designated Personnel Email" v-model="branches.handler_email" outlined readonly
                        hide-details="auto" :disabled="disabled" dense></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="TID" v-model="branches.tid" outlined :disabled="disabled" hide-details="auto"
                        dense>
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="FTID" v-model="branches.ftid" outlined :disabled="disabled" hide-details="auto"
                        dense>
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="APZ" v-model="branches.apz" outlined :disabled="disabled" hide-details="auto"
                        dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                    <v-text-field label="API" v-model="branches.api" outlined :disabled="disabled" hide-details="auto"
                        dense></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="3">
                    <v-file-input v-model="branches.business_permit.file" label="*Business Permit"
                        prepend-icon="mdi-paperclip" dense outlined hide-details="auto" :rules="fileRule">
                    </v-file-input>
                </v-col>
                <v-col :cols="3">
                    <v-menu v-model="branches.business_permit.menuIssued" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto" hide-details="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="branches.business_permit.date_issued" label="*Date Issued"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="!branches.business_permit.file ? '' : on" dense outlined hide-details="auto"
                                :rules="genericRule">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="branches.business_permit.date_issued"
                            @input="branches.business_permit.menuIssued = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col :cols="3">
                    <v-menu v-model="branches.business_permit.menuExpiry" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto" hide-details="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="branches.business_permit.date_expiry" label="*Expiry Date"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="!branches.business_permit.date_issued ? '' : on" dense outlined
                                hide-details="auto" :rules="genericRule">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="branches.business_permit.date_expiry"
                            @input="branches.business_permit.menuExpiry = false" hide-details="auto"
                            :min="branches.business_permit.date_issued">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="4">
                    <v-text-field v-model="branches.fla_user_access" label="*FLA with User Access" hide-details="auto"
                        outlined dense type="number" hide-spin-buttons :disabled="disabled" :rules="genericRule"
                        @change="generate"></v-text-field>
                </v-col>
                <v-col :cols="4">
                    <v-file-input v-model="branches.justification.file" label="*Justification" v-if="showUpload"
                        prepend-icon="mdi-paperclip" dense outlined hide-details="auto" :rules="fileRule">
                    </v-file-input>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6" sm="6" md="6">
                    <!-- Disabled and removed validation for location_code, this will be system generated upon successful submit of the updated SP -->
                    <v-text-field label="Location Code" v-model="branches.location_code" outlined type="number"
                        hide-spin-buttons disabled hide-details="auto" dense>
                    </v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                    <v-autocomplete label="*Class Code" v-model="branches.class_code_branch" outlined
                        :disabled="disabled" hide-details="auto" dense :rules="genericRule" :items="items">
                    </v-autocomplete>
                </v-col>
            </v-row>

                    <br>
                        <v-row class="mt-2">
                            <v-col cols="4">
                                <GmapAutocomplete 
                                class="searchLocation"
                                placeholder="Enter Place"
                                @place_changed='setPlace'
                                style="height: 40px; width: 100%; min0"
                                :value="addressInput"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-btn class="mb-4" :style="{'float': 'right'}" @click="addMarker" block outlined color="primary" height="40px">Set Place</v-btn>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="branches.coordinates.lat"
									label="*Latitude"
                                    readonly
                                    outlined
                                    dense
                                    :rules="genericRule"
								>
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="branches.coordinates.lng"
									label="*Longitude"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                    :rules="genericRule"
								>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <section>
                                    <GmapMap
                                        :center='center'
                                        :zoom='12'
                                        :style="{ width: '100%', height: '400px' }"
                                    >
                                        <!-- <GmapMarker
                                            :key="index"
                                            v-for="(m, index) in markers"
                                            :position="m.position"
                                            @click="center=m.position"
                                        /> -->
                            <GmapMarker :position="branches.coordinates" />
                        </GmapMap>
                    </section>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12">
                    <h4>Operation Hours</h4>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="0">
                        <v-tabs v-model="tab">
                            <v-tab v-for="day in branches.operating_hours" :key="day.day">
                                <b>{{ day.day }}</b>
                            </v-tab>
                        </v-tabs>

                        <v-checkbox v-if="canToggle" label="Monday - Friday" @click="sameValue"
                            v-model="mon_to_fri"></v-checkbox>

                                    <v-tabs-items v-model="tab">
                                        <v-tab-item
                                            v-for="(day, index) in branches.operating_hours"
                                            :key="day.day"
                                            >
                                            <v-card flat>
                                            
                                                <v-row class="my-3">
                                                    <v-col cols="4" v-if="day.isClosed === false">
                                                        <vue-timepicker append-to-body format="hh:mm A" @change="changeHandler($event, index)" v-model="day.timeStart" placeholder="*Operating hours from" input-width="300px" close-on-complete></vue-timepicker>
                                                    </v-col>
                                                    <v-col cols="4"  v-if="day.isClosed === false">
                                                        <vue-timepicker append-to-body format="hh:mm A" @change="changeHandler($event, index)" v-model="day.timeEnd" placeholder="*Operating hours to" input-width="300px"></vue-timepicker>
                                                    </v-col>
                                                    <v-col cols="3" class="my-0 py-0">
                                                        <v-checkbox
                                                            v-model="day.isClosed"
                                                            label="Closed"
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                                
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </v-col>
                        </v-row>
					</div>
                    <div class="mt-5">
                        <v-btn class="mb-4" :style="{'float': 'right'}" @click="addFLA()" v-if="!disabled" outlined color="primary"><v-icon>mdi-plus</v-icon>Add FLA</v-btn>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(fla, index) in branches.fla" :key="index">
                                <v-expansion-panel-header disable-icon-rotate>
                                    <div>
                                        <v-icon @click.stop="" @click="deleteFLA(index)" color="error" v-if="index > 0 ? 'mdi-delete' : ''">mdi-delete</v-icon>
                                        <span class="mx-2">FLA {{index === 0 ?  '' : index+1}}</span>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                    <v-row class="mb-3">
                                        <v-col cols="3">
                                            <v-text-field
                                                label="*First Name"
                                                hide-details="auto"
                                                v-model="fla.first_name"
                                                outlined
                                                :disabled="disabled"
                                                dense
                                                :rules="genericRule"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                label="*Middle Name"
                                                hide-details="auto"
                                                v-model="fla.middle_name"
                                                outlined
                                                dense
                                                :rules="genericRule"
                                                :disabled="disabled"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                label="*Last Name"
                                                hide-details="auto"
                                                v-model="fla.last_name"
                                                outlined
                                                dense
                                                :disabled="disabled"
                                                :rules="genericRule"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                label="Suffix"
                                                hide-details="auto"
                                                v-model="fla.suffix"
                                                outlined
                                                dense
                                                :disabled="disabled"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Email"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.email_address"
                                                :disabled="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Citizenship"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                v-model="fla.citizenship"
                                                readonly
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="6">
                                            <v-menu
                                                v-model="fla.menuExpiry"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="fla.date_of_seminar"
                                                        label="Date of AMLA Seminar Attended"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        dense
                                                        outlined
                                                        hide-details="auto"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="fla.date_of_seminar"
                                                    @input="fla.menuExpiry = false"
                                                    :disabled="disabled"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Tin No."
                                                outlined
                                                dense
                                                v-model="fla.tin_no"
                                                hide-details="auto"
                                                :disabled="disabled"
                                            ></v-text-field>
                                        </v-col> 
                                    </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field label="Tel No." outlined dense hide-details="auto" v-model="fla.tel_no"
                                    :disabled="disabled"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Fax No." outlined dense hide-details="auto" v-model="fla.fax_no"
                                    :disabled="disabled"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Address" outlined dense hide-details="auto" v-model="fla.address"
                                    :disabled="disabled"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2">
                                <v-checkbox label="WUPOS OP ID" color="indigo" v-model="fla.is_wupos"
                                    hide-details="auto" class="mb-5"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-if="fla.is_wupos" label="*WUPOS OP ID" hide-details="auto"
                                    v-model="fla.wupos_op_id" outlined dense :disabled="disabled"
                                    :rules="genericRule"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="USP OP ID" color="indigo" v-model="fla.is_usp" hide-details="auto"
                                    class="mb-5"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-if="fla.is_usp" label="*USP OP ID" hide-details="auto"
                                    v-model="fla.usp_op_id" outlined dense :disabled="disabled"
                                    :rules="genericRule"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-autocomplete label="*Status" outlined dense hide-details="auto" hide-spin-buttons
                                    :disabled="disabled" :rules="genericRule" :items="status"
                                    v-model="fla.fla_status"></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="*Agent Code" outlined dense hide-details="auto" hide-spin-buttons
                                    :disabled="disabled" :rules="genericRule" v-model="fla.agent_code"></v-text-field>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <b>Compliance Requirements</b>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="5">
                                <v-file-input v-model="fla.amla.file" label="*AMLA" prepend-icon="mdi-paperclip" dense
                                    outlined hide-details="auto" :rules="fileRule">
                                </v-file-input>
                            </v-col>
                            <v-col :cols="2">
                                <v-menu v-model="fla.amla.menuIssued" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto" hide-details="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="fla.amla.date_issued" label="*Date Issued"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="!fla.amla.file ? '' : on" dense outlined hide-details="auto"
                                            :rules="genericRule"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fla.amla.date_issued" @input="fla.amla.menuIssued = false"
                                        hide-details="auto"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col :cols="2">
                                <v-menu v-model="fla.amla.menuExpiry" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto" hide-details="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="fla.amla.date_expiry" label="*Expiry Date"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="!fla.amla.date_issued ? '' : on" dense outlined hide-details="auto"
                                            :rules="genericRule"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fla.amla.date_expiry" @input="fla.amla.menuExpiry = false"
                                        hide-details="auto"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- <v-col :cols="9">
                                            <v-file-input
                                                v-model="fla.drug_test.file"
                                                label="Drug Test"
                                                prepend-icon="mdi-paperclip"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="fileRule"
                                                >
                                            </v-file-input>
                                        </v-col> -->
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="9">
                                            <v-file-input
                                                v-model="fla.nbi.file"
                                                label="*NBI Clearance"
                                                prepend-icon="mdi-paperclip"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="fileRule"
                                                >
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row>
                                        <v-col :cols="9">
                                            <v-file-input
                                                v-model="fla.valid_id.file"
                                                label="*Valid ID"
                                                prepend-icon="mdi-paperclip"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="fileRule"
                                                >
                                            </v-file-input>
                                        </v-col>
                                    </v-row> -->
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                <!-- </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels> -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
export default {
    props: ['disabled'],
    components: {
        VueTimepicker: VueTimepicker
    },
    data() {
        return {
            items: ["Send and Receive", "Send Only", "Receive Only"],
            status: ['Active', 'Separated'],
            showUpload: null,
            center: { lat: 14.5528063, lng: 121.0167313 },
            currentPlace: null,
            places: [],
            addressInput: '',
            tab: null,
            breadcrumbList: [],
            menu: false,
            mon_to_fri: null,
            canToggle: false
        };
    },
    computed: {
        ...mapGetters({
            branches: 'salesProspecting/branches',
            genericRule: 'salesProspecting/genericRule',
            fileRule: 'salesProspecting/fileRule',
            emailRule: 'salesProspecting/emailRule',
            province: 'salesProspecting/province',
            branchesCity: 'salesProspecting/branchesCity',
            branchesZip: 'salesProspecting/branchesZip',
            users: 'salesProspecting/users',
            hrisToken: 'auth/hrisToken',
            primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
            branchMailingCity: 'salesProspecting/branchMailingCity',
            branchMailingZip: 'salesProspecting/branchMailingZip',
        }),
    },
    mounted() {
        this.geolocate();
    },
    methods: {
        checkAddress() {
            if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
                return false
            } else {
                return true
            }
        },
        sameAddress() {
            if (this.branches.is_same_primary) {
                console.log('check')
                this.branches.is_same_primary = true
                this.branches.mailing_address_line_1 = this.primaryBusinessAddress.address_line_1
                this.branches.mailing_address_line_2 = this.primaryBusinessAddress.address_line_2
                this.branches.mailing_country = this.primaryBusinessAddress.country
                this.branches.mailing_city = this.primaryBusinessAddress.city
                this.branches.mailing_region = this.primaryBusinessAddress.region
                this.branches.mailing_postal_code = this.primaryBusinessAddress.postal_code
                this.branches.mailing_province = this.primaryBusinessAddress.province
                this.branches.mailing_landline = this.primaryBusinessAddress.landline
                this.branches.mailing_cellphone = this.primaryBusinessAddress.cellphone
            } else {
                console.log('uncheck')
                this.branches.mailing_address_line_1 = ''
                this.branches.mailing_address_line_2 = ''
                this.branches.mailing_country = 'Philippines'
                this.branches.mailing_city = ''
                this.branches.mailing_province = ''
                this.branches.mailing_region = ''
                this.branches.mailing_postal_code = ''
                this.branches.mailing_landline = ''
                this.branches.mailing_cellphone = ''
            }
        },
        async getRegionMialing(province) {
            this.branches.mailing_postal_code = ''
            this.branches.mailing_city = ''
            const payload = {
                payload: province,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGetBranchesMailingRegion', { payload })

            const finalPayload = {
                payload: this.branches.mailing_region,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGeBranchesMailingCity', { finalPayload })
        },
        async getZipMailing(city) {
            const payload = {
                payload: this.branches.mailing_region,
                city: city,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGetBranchesMailingZip', { payload })
        },
        getHandlerEmail(i) {
            console.log(i)
            this.branches.handler_name = i.name
            this.branches.handler_email = i.email
        },
        sameValue() {
            console.log(this.mon_to_fri)
            if (this.mon_to_fri === true) {
                let start = this.branches.operating_hours[0].timeStart
                let end = this.branches.operating_hours[0].timeEnd

                this.branches.operating_hours[1].timeStart = start
                this.branches.operating_hours[1].timeEnd = end
                this.branches.operating_hours[2].timeStart = start
                this.branches.operating_hours[2].timeEnd = end
                this.branches.operating_hours[3].timeStart = start
                this.branches.operating_hours[3].timeEnd = end
                this.branches.operating_hours[4].timeStart = start
                this.branches.operating_hours[4].timeEnd = end
                this.branches.operating_hours[5].isClosed = true
                this.branches.operating_hours[6].isClosed = true
            } else {
                this.branches.operating_hours[5].isClosed = false
                this.branches.operating_hours[6].isClosed = false
            }

        },
        async getRegion(province) {
            this.branches.postal_code = ''
            this.branches.city = ''
            const payload = {
                payload: province,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGetBranchesRegion', { payload })

            const finalPayload = {
                payload: this.branches.region,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGeBranchesCity', { finalPayload })
        },
        async getZip(city) {
            const payload = {
                payload: this.branches.region,
                city: city,
                token: this.hrisToken
            }
            await this.$store.dispatch('salesProspecting/doGetBranchesZip', { payload })
        },
        generate(value) {
            let newValue = (parseInt(value))
            if (newValue > 5) {
                this.showUpload = true
            } else {
                this.showUpload = false
            }
        },
        setPlace(place) {
            this.currentPlace = place;
            this.addressInput = ''
        },
        addMarker() {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.center = marker;
                this.currentPlace = null;
                const payload = { lat: marker.lat, lng: marker.lng }
                this.$store.commit('salesProspecting/SET_BRANCH_COORDINATES', { payload })
            }
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            });
        },
        changeHandler(event, index) {

            if (this.branches.operating_hours[0].timeStart && this.branches.operating_hours[0].timeEnd) {
                this.canToggle = true
            } else {
                this.canToggle = false
            }


            console.log(event, index)
            // const {A, hh, mm} = event.data
            // if (!A || !hh || !mm) {
            //     console.log(this.branches[0].days[index].timeStart)
            //     this.branches[0].days[index].timeStart = ''
            // }
        },
        addFLA() {
            this.branches.fla.push({
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                wupos_op_id: '',
                usp_op_id: '',
                fla_status: '',
                amla: {
                    file: null,
                    date_issued: '',
                    date_expiry: '',
                    menuIssued: false,
                    menuExpiry: false
                },
                nbi: {
                    file: null,
                },
                email_address: '',
                citizenship: 'Filipino',
                date_of_seminar: '',
                tin_no: '',
                tel_no: '',
                fax_no: '',
                is_wupos: null,
                is_usp: null,
                menuExpiry: false
            })
        },
        deleteFLA(index) {
            this.branches.fla.splice(index, 1)
        }
    }
};
</script>
<style>
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus {
    background: #1A2791;
    color: #fff;
}

html {
    font-family: 'Roboto', sans-serif;
}

.searchLocation {
    border: 1px solid black;
    padding: 10px;
    border-radius: 4px;
}
</style>
